
:root {

  --text-gray: #777777;
  --text-dark: #626261;
  --text-brown: #6D6662;
  --text-green: #7C764C;
  --text-light: #F7F4ED;
  --text-grey2: #777169;
  --text-brown-dark: #634B37;

  --light-background: #FCFCFC;
  --light-background2: #F6F3EC;
  --light-background3: #FAFAFA;
  --background-green: #7B754C;

  --border-brown: #8F7E76;
  --border-gray: #D9D9D5;
  --border-gold: #E5CD9C;
}
